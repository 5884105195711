import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { CardWithImageLink } from '../../../components/Card';
import { ButtonOutbound, ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import { OutboundLink } from '../../../components/StyledLink';
import { CallToAction } from '../../../components/CallToAction';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Text } from '../../../components/Text';
import { Box, ProjectBoxDisabled } from '../../../components/Box';
import ImageIcon from '../../../components/ImageIcon';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Hatch" mdxType="SEO" />
    <IntroProject projectName="Hatch" projectDescription="Automating and randomizing pairs for teams that pair program" projectYear="2020" projectImage="hatch-preview" mdxType="IntroProject" />
    <Section section="Background" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Overview</h5>
          <Box center marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.grey800} color={({
              theme
            }) => theme.grey100} mdxType="Box">
            <div style={{
                width: '104px',
                margin: 'auto'
              }}>
              <ImageIcon srcName="hatch-example-icon" mdxType="ImageIcon" />
            </div>
            <h5>Coming Soon</h5>
            <p>I'm still in the building and internal testing phase of this app, but find a few snippets of the initial design process below</p>
          </Box>
          <IconCard cardTitle="Specifications" cardText="A simple app to help randomize, schedule, and automate pairs for teams that pair program" mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
          </IconCard>
          <IconCard cardTitle="Tools / My Role" cardText="Figma, SwiftUI, React, Firebase, Node / UI Design, user research, web development, mobile development, product strategy" mdxType="IconCard">
            <MdHammer fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdHammer" />
          </IconCard>
          <p>After joining a team in which the developers on my team pair program, I saw that their process was manual and tedious. Essentially, a team lead would copy/paste a block of pairs in Slack every morning to designate pairs. <strong>But, what if we could make this process less tedious for team leads and managers?</strong></p>
          <ul>
            <li>What if the team lead could automate this task on a schedule?</li>
            <li>What if they could pick teams through an interface?</li>
            <li>What if managers/team leads wanted to look at the history of pairs without having to dig through Slack?</li>
          </ul>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Before/After process flow</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAACE4AAAhOAFFljFgAAAB0UlEQVQ4y41T247TQAzdf18hIfHEFyBuQnwFAonXVREvu7CIZVva0mYmkzSXuR/smSRNKyrxYI1zbB+fGTtXMZYAFIwtYJ1IPmMJJwteJpyN/TGOISfm7zjg8Wok9L2A220Qii2C4cIqWSQ/YWRxjgdqVhUIuzXCoYgDz0hInWwN8+IlzLv3REjdmt+I9Qqh3sG8fgvz6g3hRNSuEaslQrOBWyzQXl/Db1YxcqOjQiasEG5vEX7ckxIi9AWiIwWalNzdJcu4yLhldVv4mxtqKs4VVvDNGkb+gt7/hO+209V8u4EuHij2OMPzOxrG1RJWLc/fMBfa8gFWEGGfC9OjBwmviKx6PB0E4VbmfFevjoTT1FLnerAK47AcTbfVBTpzugV8zvLPr3y2KlMBTZ9WhUnZfJATHuarM1+bMSEDKg2otQcstj2+kPXuMMU78r/+yTjnpGGmGhWHnCNh7sS+RO87PPkg8ewTk7eotYTqBETX4PlnhacfSyLvUu5FwrlCVvJNaNxLnZQ4WmI29r8TxrHO/ZfC/HadEZCHHVSzh7ZiamidREmYoGXXdvYbohwIy38TBlLDZFW7nwbESjiuCGPSMBvmxSufrsQpdgkficbzLx0KOZVH6q81AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Process Flow",
          "title": "Process Flow",
          "src": "/static/45c74d43f4ff6e4a95c4ec10667f0314/d9199/process-1.png",
          "srcSet": ["/static/45c74d43f4ff6e4a95c4ec10667f0314/8ff5a/process-1.png 240w", "/static/45c74d43f4ff6e4a95c4ec10667f0314/e85cb/process-1.png 480w", "/static/45c74d43f4ff6e4a95c4ec10667f0314/d9199/process-1.png 960w", "/static/45c74d43f4ff6e4a95c4ec10667f0314/07a9c/process-1.png 1440w", "/static/45c74d43f4ff6e4a95c4ec10667f0314/29114/process-1.png 1920w", "/static/45c74d43f4ff6e4a95c4ec10667f0314/97a96/process-1.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Preview for creating and scheduling pairs</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLElEQVQ4y3WT62rcMBCF8/5v0vcoFAql/VsKhU2TrNf26mLJulrndGxvUi9JBZ91QT6aMyM9DIk40kfpw4H5gD/gDkwrwGCJh36u6IzH2cx40QH9lO+x91yMoBM6HXFWCb0psr6LDdI/qAiUHGhevlKpK1trXJaFtS5bv8931nGVtZwLXSY7x3Xfe8G1JWcxuRn91cA5j3kOyDmD5BtrM9bB2RHz4ydk/Z0LJE2mieibYNs2jlfN7jIglwUhBJkrlFLehF5FkxxSckQLj+Bi10WMt/wNhv8ElbEclF5/Ys0Tg3sUi5pYJtZh4GI06WdO2fFcNU1JUGqi0gaSCoTUJLeHCLX1ImhRapOQEltVcraUr3kJZGJzkrAQmJfEUSIb/EglLszksSyVIVZcVBPBsAs+dyNPz/3qgDY1nkzlmEgpKL1qjA7Ms0TvJWp/4SKTW4H+L/jUjZvltJBDhNgSl5UsAQymbYJNPi1p5qiZYmCpdQvinaCZHEZtt8RfjcOXH7/w+dtP/P7T74VZa7PBjRI8vLVMeSvcLni9Ca4LcmXoQlyHLKVSGUdlHeeQ+FErKTHGCLG9/e982gXHuWFOFedBo9duGwchl7YR84I5FqHeoVyAlyLVzTLl4LIL9h64OCn5dMA2dCtyYS9H9E4nubqojJSy+G+8E7x78B8++hv2nssYYcwE5yVS4arnV0G55SvuwHTAHjCQZ4btRZzHitOzEzxOTw5PXcSgiL9yxogt8jbMEAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Preview",
          "title": "Preview",
          "src": "/static/1aad3cf4615d20369f75eca1d313b559/d9199/preview-1.png",
          "srcSet": ["/static/1aad3cf4615d20369f75eca1d313b559/8ff5a/preview-1.png 240w", "/static/1aad3cf4615d20369f75eca1d313b559/e85cb/preview-1.png 480w", "/static/1aad3cf4615d20369f75eca1d313b559/d9199/preview-1.png 960w", "/static/1aad3cf4615d20369f75eca1d313b559/07a9c/preview-1.png 1440w", "/static/1aad3cf4615d20369f75eca1d313b559/29114/preview-1.png 1920w", "/static/1aad3cf4615d20369f75eca1d313b559/97a96/preview-1.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Atoms of the design system</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAACE4AAAhOAFFljFgAAAC9ElEQVQ4y41US08TURSe/+PGlTtXJixN0LUSXbFwoQsTV7CXhcbEaEx8hPhYqKD4AAQbFI2BApZKKbSdQlva6cx0ykznfefxee8MhWkshpPcufeeO/Odc77z3eGAEMzCMEQQBNHc3fezI39I1wFz9HzLsXNCnGM+6g/m+wFMuwvbaxxCgsb6XdSKP5DLl+F55PDjfoOZ57nQhGX4Zpn6PLguQaFQoH6PAtIYpFOAbbRgOwT/twM6XBn+0imEm5dougJs18fFC4Mo8zwrOcSe0IYgKpDlFgzThNbpQNM6MAwTum6g2RTR6eiUmjggIS5KuRSEagZKuw2p1cZmfotS4YNjj/nUAjZym5ie/YqpjzN4MzGFD59mUSyVsbKawbPxV3j89DmlZDsClGjgs+cGMXB+CEPDtzA69hjv59OwbDfOkGXBMtrfV9Fu70cZKnRmaxaQveO6btTJOEMP03PfMJf6ifTvHPLFChq0QnYeAYqijIbQxNZ2ESV+B4rSRqulRGvbtv9hkng+yooJ2QlhkADEDxJdpqaqGloUhHFomhbqDSHiLymXZJeJZWF34i30hRQ8yqHPNBjE5xxLU6Ckl/gyKtUaqtW9qDF99diVDU0ge3UY0s0R+KWduPcHAeOSJRlNUYrKZoS7tJtMU6yrjC9ysPfpiAAdA9nJUTTXxkDs3T6AFGyv3kCDlspAWcZsZoOVX6/HfvaeYdnwHR1rL6+jvjwCz+IRhAlAJlbfP8qimxVbx3vv0O84Lny6V20T117fwY3Fh8gbtR6eOQZO5QMS4MRmuTbufZnA/fQMKrocXaAwmaGeuYLa9GnUZs9A/D4AU2/REmXsVqpRyd0bEgRxU1ST4PLtXxh6soOsSHrUwLHaPfEdzPIDdPhHsCrj8FyLyseOrhuTDxN3susG1ebkShZLvIqaSP8FqglH0mIdhicsM6lDUdHwYnEdUqWJzOc0NL4JJc1H3ebi7nhUmIlxzG+rO+u6iz+rGnSpAXVjrifoX5pSUAxs9CVSAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design System",
          "title": "Design System",
          "src": "/static/04c81aee51751f2131427533bbfe91af/d9199/design-system-1.png",
          "srcSet": ["/static/04c81aee51751f2131427533bbfe91af/8ff5a/design-system-1.png 240w", "/static/04c81aee51751f2131427533bbfe91af/e85cb/design-system-1.png 480w", "/static/04c81aee51751f2131427533bbfe91af/d9199/design-system-1.png 960w", "/static/04c81aee51751f2131427533bbfe91af/07a9c/design-system-1.png 1440w", "/static/04c81aee51751f2131427533bbfe91af/29114/design-system-1.png 1920w", "/static/04c81aee51751f2131427533bbfe91af/97a96/design-system-1.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Example UI for sigining in and the 404 route</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAACE4AAAhOAFFljFgAAACM0lEQVQ4y31U23LTMBDtR/IN/BYzDLcv4I2XDuWp3NMpMwyFtGmbxrZsS5Z139OV7CYpLShzos2udPZotcoBUQui7lEAOdbvQbLPMTRD7sdoXk8HO8IWdzYwgUggJYM8KH9ShG6+oa5uEGKcvJSRKEaR99DBtLll9DMm4uxPSSDGO8I8Ekx7AilzbPKU2D5h3pySRooa3o8c9BwIBdnmpTyXTXA+YEeDrX2PMKtIrKK7eIfz5Rn06BBCvKegaEsJg7ZQjHUjmTyW43LGRwi5TkpJtF2PrlcQbc8b/JydtrPoNc4uBU7P1qilQZISqapKJR8oVKNH0ylo62FcgDYOwwxtXVE8ckxpBzlYWB8fr2GiqfCDIfQjwLwTws5mDl6ToLVGDIHJI5yzGAYNy8keHDkmCyOXUPVnmO4EWiwwNN/ZXhQMzYJP4XH64yfef/iIw6NjfPqywJ/lOVarq5xg75ZLa/BFiEO41TNg8xrx6iXbL0A3r2BXz+Gv35SyVNUGF7+Z6NdXbNYX6KWCMfYvwvnIgWvvUu603chVcvzl09QiXi0hjp9ic/QE5vrtXtvgISHyrVruQzMiihqRXwPpgX2mINdNa8FmzahYmWB0GMeW55Zy6e5qyD8sGW4r6UG9I7pqDV02I4kxkuJeVuxP3DfeV6ykyWrmuS5IqaFMxs92einOSRhWYVjhyFCjLtCsNvsyrOXXlNrtO/8X8lsu2XL24OuCGCaEUM++qtic/L/I/za3KTeLMHc23MoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Log in and 404",
          "title": "Log in and 404",
          "src": "/static/d140556fcf4284ec4d369dd2f6bdf991/d9199/screens-1.png",
          "srcSet": ["/static/d140556fcf4284ec4d369dd2f6bdf991/8ff5a/screens-1.png 240w", "/static/d140556fcf4284ec4d369dd2f6bdf991/e85cb/screens-1.png 480w", "/static/d140556fcf4284ec4d369dd2f6bdf991/d9199/screens-1.png 960w", "/static/d140556fcf4284ec4d369dd2f6bdf991/07a9c/screens-1.png 1440w", "/static/d140556fcf4284ec4d369dd2f6bdf991/29114/screens-1.png 1920w", "/static/d140556fcf4284ec4d369dd2f6bdf991/75ac7/screens-1.png 3600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Experience for pairing teams on mobile</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.41666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACl0lEQVQ4y4WT+1NSQRTH/WvrR+3nZrJ0psmURMQ3Pn6w1IZEEAEZbXxFgO/QUfN9RUUQH8kb7v20e+FajWU7c2bv7j3ns99z9mzVGQWknWp5yt9FdlPX7Kdv2EzGCB3uEtzfZvXsmLVzhUj8lJ2bS6IV/0qcVo7Na1XGZvlHka9bG4y6x3n+spZX9fXUPKvhRW0t1WJ+8vQp1TU11NXVMbEwSxKIlnIy9iFQzoqaZfrLAomzGEfHCqlUBpfLhamxkTZrG62tVvr7+7GYWxged1aA2b8DpcXQmAkHuYonyBdV5BhzODA1NdHV3UN3j43evj7M75oZcbseVxhVcySEw7QAJi+TlEolVFXFbrdjsVh0ZZ2dndh6e2kxm/ngHOVSAtVHgNLBHw5wnUxgDIdjFHNzEzZbrzAbAwMDtLZYGHI5/g+UmLnIClHlO5lsTgc6xxy8eV2P1WrVTap829DAoOPTv4EGNC4clo732Vlfp5DLUVI1JtxufF4fwVCY5ZUVIpEIwUAAz8xnveaV+uvAUwk0LkTaBSqbl+csh0MU8gWKxRJ+v594PE6pWLwvg6ZpBNZEJoju0HQxmgF+ANy+STAvgMaQwFgsRk4ckLpNk7vLcHt1zcLyolB0n51mzH+kLIFb1xdMBUJ62+Tzebxer+jHtA7P/hLJ3FKYo0KK00oNjRdzr/BENKi0b/Eo/vkA6VSebDaLx+PB4/Xfm9szidM5gcs/yZbI5jD7g+NCWjupNHiVvCVFLMQmh5lbNsT7dfl8HOzsoihK+VYbTbRaO2jv6MLa3oXJ1Mz7sVH9zR+IGAMoWGWgXCjFDIe5Ow7EiVOri/QMDWL7OIzNPsKAw86Qdxz79CTuwCw+0avhoz32BPB3hRL4Ez1Q4cdCJBoeAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mobile screens",
          "title": "Mobile screens",
          "src": "/static/693decd75b3831c74d4304360dc3c312/d9199/mobile-1.png",
          "srcSet": ["/static/693decd75b3831c74d4304360dc3c312/8ff5a/mobile-1.png 240w", "/static/693decd75b3831c74d4304360dc3c312/e85cb/mobile-1.png 480w", "/static/693decd75b3831c74d4304360dc3c312/d9199/mobile-1.png 960w", "/static/693decd75b3831c74d4304360dc3c312/07a9c/mobile-1.png 1440w", "/static/693decd75b3831c74d4304360dc3c312/29114/mobile-1.png 1920w", "/static/693decd75b3831c74d4304360dc3c312/97a96/mobile-1.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Coming Soon" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <Box center bg={({
              theme
            }) => theme.grey800} color={({
              theme
            }) => theme.grey100} mdxType="Box">
            <div style={{
                width: '104px',
                margin: 'auto'
              }}>
              <ImageIcon srcName="hatch-example-icon" mdxType="ImageIcon" />
            </div>
            <h5>Coming Soon</h5>
            <p>If you're a team that pair programs, I'd love to connect, get feedback on your particular process, and add you to the beta list 😎.</p>
            <ButtonOutbound bg={({
                theme
              }) => theme.green} href="mailto:parag.ryan@gmail.com?subject=Hatch App" mdxType="ButtonOutbound">Get in contact</ButtonOutbound>
          </Box>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      